<script setup lang="ts">
interface _VTI_TYPE_WritableComputedRef {
    value: _VTI_TYPE_T;
    /**
     * Type differentiator only.
     * We need this to be in public d.ts but don't want it to show up in IDE
     * autocomplete, so we use a private Symbol instead.
     */
    [RefSymbol]: true;
    readonly effect: _VTI_TYPE_ReactiveEffect;
}
interface Props {
    label?: string;
    inlineLabel?: boolean;
    placeholder?: string;
    elementClassName?: string;
    containerClassName?: string;
    className?: string;
    inputId?: string;
    inputType?: string;
    step?: number;
    disabled?: boolean;
    withoutLabel?: boolean;
    tooltip?: string;
    autofocus?: boolean;
    required?: boolean;
    translated?: boolean;
    withVariables?: boolean;
    form: _VTI_TYPE_Form;
    model?: _VTI_TYPE_WritableComputedRef;
    inputName: string;
    withNullOption?: boolean;
        position?: string;
        minTime?: string;
        maxTime?: string;
        disabled: boolean;
    }
    import VueDatePicker from '@vuepic/vue-datepicker'
    import { FormInputData } from '~~/composables/useFormInput'
    import '@vuepic/vue-datepicker/dist/main.css';
    
    const emit = defineEmits(["change"]);
    const props = defineProps({
  label: null,
  inlineLabel: { type: Boolean },
  placeholder: null,
  elementClassName: null,
  containerClassName: null,
  className: null,
  inputId: null,
  inputType: null,
  step: null,
  disabled: { type: Boolean },
  withoutLabel: { type: Boolean },
  tooltip: null,
  autofocus: { type: Boolean },
  required: { type: Boolean },
  translated: { type: Boolean },
  withVariables: { type: Boolean },
  form: null,
  model: null,
  inputName: null,
  withNullOption: { type: Boolean },
  position: { default: 'top-full left-0' },
  minTime: null,
  maxTime: null
});
    const inputData: FormInputData = useFormInput(props);
    const minTime = computed(() => {
        if (!props.minTime) {
            return null;
        }
        return moment(props.minTime).toTimepickerObject();
    });
    const maxTime = computed(() => {
        if (!props.maxTime) {
            return null;
        }
        return moment(props.maxTime).toTimepickerObject();
    });
    const time = computed({
        get() {
            return moment(inputData.model.value).toTimepickerObject();
        },
        set({ hours, minutes }: { hours: number; minutes: number }) {
            const time = moment(inputData.model.value).hour(hours).minute(minutes).toDateTimeString();
            inputData.form.set(inputData.inputName, time);
            if (inputData.inputName === 'maxEndsAt') emit('change', time);
        },
    });
    const formattedTime = computed(() => (inputData.model.value ? moment(inputData.model.value).toShortTimeString() : ''));
</script>

<template>
    <Submenu
        :container="$theme('submenu.container.withoutPosition')"
        without-max-height
        :position="position"
    >
        <template #default="{ show, hide }">
            <FormElement
                :input-data="inputData"
                @label-clicked="show"
            >
                <input
                    :id="inputData.inputId.value"
                    :required="inputData.required.value"
                    :placeholder="inputData.placeholder.value"
                    :class="inputData.className.value"
                    :name="inputData.props.inputName"
                    :value="formattedTime"
                    :disabled="disabled"
                    @focus="show"
                />
            </FormElement>
        </template>
        <template #submenu="{ hide }">
            <div class="py-2">
                <slot
                    name="datepicker"
                    :hide="hide"
                >
                    <VueDatePicker
                        v-model="time"
                        time-picker
                        inline
                        hide-input-icon
                        auto-apply
                        :clearable="false"
                        required
                        :min-time="minTime"
                    />
                </slot>
            </div>
        </template>
    </Submenu>
</template>
